jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true,
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .main-nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".dropdown-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	// Remove underline from link images
	$('img').parent('a').css('border-bottom', '0px');

	/* eslint-disable */
	/* Webflow */
	Webflow.require('ix').init([
		{"slug":"dropdown-hover","name":"Dropdown Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdown-list","descend":true,"stepsA":[{"opacity":1,"height":"auto","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}],"stepsB":[{"opacity":0,"height":"0px","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{"slug":"cta-animation","name":"CTA Animation","value":{"style":{},"triggers":[{"type":"hover","selector":".cta-content","descend":true,"preserve3d":true,"stepsA":[{"opacity":1,"height":"auto","transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0,"height":"0px","transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms","x":"0px","y":"20px","z":"0px"}]},{"type":"hover","selector":".cb-heading","descend":true,"preserve3d":true,"stepsA":[{"opacity":1,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0.8,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","x":"0px","y":"33px","z":"0px"}]}]}},
		{"slug":"left-nav-arrow-animation","name":"Left Nav Arrow Animation","value":{"style":{},"triggers":[{"type":"hover","stepsA":[],"stepsB":[]}]}}
	]);
	/* eslint-enable */

	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});
});
